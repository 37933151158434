import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { ROUTES } from '~routes'
import logo from '~img/logo.svg'
import Footer from '~globalComponents/Footer'
import { ERRORS } from './constants'
import './style.scss'

export const Error = ({ withRedirect }) => {
  const { search } = useLocation()
  const { type = 'primary' } = qs.parse(search)

  return (
    <div className="wrap wrap--service">
      <section className="s360_error">
        <div className="s360_error_logo">
          <img src={logo} alt="Sense 360" />
        </div>
        <h3 className="s360_error_desc">{ERRORS[type]}</h3>
        {withRedirect && (
          <Link className="s360_error_link" to={ROUTES.HOME}>
            Go back to the home page
          </Link>
        )}
      </section>
      <Footer hasHelpCenter={false} />
    </div>
  )
}
